// constants
export const sectorsList = [
  { value: 'foodSupplement' },
  { value: 'other' },
  { value: 'chemical' },
  { value: 'cosmetic' },
  { value: 'detergents' },
  { value: 'medicalDevice' },
  { value: 'packaging' },
  { value: 'biocide' },
  { value: 'pharmaceutical' },
];

export const sampleDescriptionsList = [
  { value: 'anhydrousFluid' },
  { value: 'anhydrousPaste' },
  { value: 'sanitaryTowelsDiapers' },
  { value: 'biphasic' },
  { value: 'capsule' },
  { value: 'tablet' },
  { value: 'emulsion' },
  { value: 'liquidGel' },
  { value: 'liquid' },
  { value: 'oil' },
  { value: 'packaging' },
  { value: 'patch' },
  { value: 'pressedPowder' },
  { value: 'loosePowder' },
  { value: 'wipes' },
  { value: 'validation' },
];

export const ANALYSES_TYPES = {
  FIXED: 0,
  SINGLE: 9999,
};
export const ANALYSES_COMMODITY_CATEGORIES = {
  // MICROBIOLOGICAL: 'MICROBIOLOGICAL',
  // CHEMICAL: 'CHEMICAL',
  MICROBIOLOGICAL: 'MICROBIOLOGIA',
  CHEMICAL: 'CHIMICO',
};

export const COMPLIFE_LABORATORY = {
  MICR: 'MICR',
  CHIM: 'CHIM',
};

export const SAMPLE_STATUSES = {
  CREATED: 0,
  PENDING: 1,
  ACCEPTED: 2,
  INPROGRESS: 3,
  DRAFT: 4,
  CONCLUDED: 5,
  COMPLETED: 5,
  CANCELED: 6,
  REJECTED: 7,
  SUSPENDED: 8,
};

export const ANALYSIS_STATUSES = {
  NONE: 0,
  ACCEPTED: 1,
  INPROGRESS: 2,
  COMPLETED: 3,
  DELETED: 4,
  SUSPENDED: 5,
  PENDING: 6,
  REJECTED: 7,
};

export const NEWS_STATUSES = {
  UNPUBLISHED: 0,
  PUBLISHED: 1,
};

export const ORDER_LOCKED = {
  NONE: 0,
  ACCEPTANCE: 1,
  REPORT: 2,
  ACCEPTANCE_REPORT: 3,
};

export const USER_FUNCTIONS = {
  QC: 0,
  QA: 1,
  RD: 2,
  PRODUCTION: 3,
  ADMINISTRATION: 4,
  LOGISTICS: 5,
  PUR_OFF: 6,
};

export const USER_FUNCTIONS_DESC = {
  [USER_FUNCTIONS.QC]: 'qc',
  [USER_FUNCTIONS.QA]: 'qa',
  [USER_FUNCTIONS.RD]: 'rd',
  [USER_FUNCTIONS.PRODUCTION]: 'production',
  [USER_FUNCTIONS.ADMINISTRATION]: 'administration',
  [USER_FUNCTIONS.LOGISTICS]: 'logistics',
  [USER_FUNCTIONS.PUR_OFF]: 'purOff',
};

export const SAMPLE_STATUSES_DESC = {
  [SAMPLE_STATUSES.CREATED]: 'created',
  [SAMPLE_STATUSES.PENDING]: 'pending',
  [SAMPLE_STATUSES.ACCEPTED]: 'accepted',
  [SAMPLE_STATUSES.INPROGRESS]: 'inprogress',
  [SAMPLE_STATUSES.TOBEAPPROVED]: 'inprogress', // internal statuses
  [SAMPLE_STATUSES.TOBEVERIFIED]: 'inprogress', // internal statuses
  [SAMPLE_STATUSES.DRAFT]: 'draft',
  [SAMPLE_STATUSES.CONCLUDED]: 'concluded',
  [SAMPLE_STATUSES.COMPLETED]: 'concluded',
  [SAMPLE_STATUSES.CANCELED]: 'canceled',
  [SAMPLE_STATUSES.REJECTED]: 'rejected',
  [SAMPLE_STATUSES.SUSPENDED]: 'suspended',
};

export const ANALYSIS_STATUSES_DESC = {
  [ANALYSIS_STATUSES.NONE]: 'none',
  [ANALYSIS_STATUSES.ACCEPTED]: 'accepted',
  [ANALYSIS_STATUSES.INPROGRESS]: 'inprogress',
  [ANALYSIS_STATUSES.COMPLETED]: 'completed',
  [ANALYSIS_STATUSES.DELETED]: 'deleted',
  [ANALYSIS_STATUSES.SUSPENDED]: 'suspended',
  [ANALYSIS_STATUSES.PENDING]: 'pending',
  [ANALYSIS_STATUSES.REJECTED]: 'rejected',
};

export const ORDER_STATUSES_DESC = {
  sent: 'sent',
  draft: 'draft',
  accepted: 'accepted',
  inProgress: 'inProgress',
  draftCompleted: 'draftCompleted',
  rejected: 'rejected',
  canceled: 'canceled',
  completed: 'completed',
};

export const NEWS_STATUSES_DESC = {
  [NEWS_STATUSES.UNPUBLISHED]: 'unpublished',
  [NEWS_STATUSES.PUBLISHED]: 'published',
};

export const SAMPLE_REPORTS_STATUSES = {
  draft: 'draft',
  final: 'final',
};

export const ORDER_LOCKED_DESC = {
  [ORDER_LOCKED.NONE]: 'none',
  [ORDER_LOCKED.ACCEPTANCE]: 'acceptance',
  [ORDER_LOCKED.REPORT]: 'report',
  [ORDER_LOCKED.ACCEPTANCE_REPORT]: 'acceptance',
};

export const permissionsList = [
  {
    label: 'admin',
    value: 'admin',
  },
  {
    label: 'news',
    children: [
      { value: 'newsView', label: 'newsView' },
      { value: 'newsManage', label: 'newsManage' },
    ],
  },
  {
    label: 'offers',
    children: [
      { value: 'offersView', label: 'offersView' },
      { value: 'offersManage', label: 'offersManage' },
    ],
  },
  {
    label: 'orders',
    children: [
      { value: 'ordersView', label: 'ordersView' },
      { value: 'ordersManage', label: 'ordersManage' },
    ],
  },
  {
    label: 'samplesStatus',
    children: [
      { value: 'samplesStatusView', label: 'samplesStatusView' },
      { value: 'samplesStatusManage', label: 'samplesStatusManage' },
    ],
  },
  {
    label: 'billing',
    children: [
      { value: 'billingView', label: 'billingView' },
      { value: 'billingManage', label: 'billingManage' },
    ],
  },
  {
    label: 'users',
    children: [
      { value: 'usersView', label: 'usersView' },
      { value: 'usersManage', label: 'usersManage' },
    ],
  },
  {
    label: 'customers',
    children: [
      { value: 'customersView', label: 'customersView' },
      { value: 'customersManage', label: 'customersManage' },
    ],
  },
];
